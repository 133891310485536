<template>
  <div>
    <div class="p-4 flex justify-between">
      <div>Mis cursos</div>
      <Button :to="{ name: 'courses.new' }">Añadir</Button>
    </div>

    <template v-if="!courses">
      <div class="p-4 pt-0">
        <Alert>
          <b>No tienes ningun curso</b>
          <br />
          Al añadir algun curso aparecera aqui.
        </Alert>
      </div>
    </template>

    <template v-else>
      <TransitionComponent isGroup name="slide-fade" tag="div">
        <div
          v-for="(course, key) in courses"
          :key="course.name"
          class="flex items-center pt-2 pb-2 pr-4 pl-4 transition-all hover:bg-gray-100 hover:shadow-lg"
        >
          <div
            class="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white"
          >
            {{ course.name[0] }}
          </div>

          <div class="ml-4 flex-initial flex-shrink-1 w-full truncate">
            <div class="text-sm font-medium text-gray-900 truncate">
              {{ course.name }}
            </div>
            <div class="text-xs text-gray-500 truncate">
              {{ course.description }}
            </div>
          </div>
          <div class="ml-4">
            <Dropdown>
              <template #activator>
                <DotsVertical class="text-red bg-red w-5" />
              </template>
              <template #content>
                <DropdownMenuItem
                  is="router-link"
                  :to="{ name: 'courses.edit', params: { id: key } }"
                >
                  Editar
                </DropdownMenuItem>
                <DropdownMenuItem @click="confirmDeleteCourse(key)">
                  Eliminar
                </DropdownMenuItem>
              </template>
            </Dropdown>
          </div>
        </div>
      </TransitionComponent>
      <CoursesDeleteModal
        :isOpen="isOpenModalDeleteCourse"
        @update:isOpen="value => (isOpenModalDeleteCourse = value)"
        @click:deleteCourse="deleteCourse"
      />
    </template>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'

// Courses store
import { COURSES_STORE_NAME } from '@/store/modules/courses'
import { DELETE_COURSE } from '@/store/modules/courses/actions.type'

// Notifications store
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { PUSH_NOTIFICATION } from '@/store/modules/notifications/actions.type'

import DotsVertical from '@/components/icons/heroicons/DotsVertical.vue'
import CoursesDeleteModal from './CoursesDeleteModal.vue'
import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import TransitionComponent from '@/components/common/transition/Transition.vue'
import Button from '@/components/common/button/Button.vue'
import Alert from '@/components/common/alert/Alert.vue'

export default {
  components: {
    CoursesDeleteModal,
    Button,
    DotsVertical,
    Dropdown,
    DropdownMenuItem,
    Alert,
    TransitionComponent
  },
  setup() {
    const store = useStore()
    const courses = computed(() => store.getters[COURSES_STORE_NAME + '/data'])
    const isOpenModalDeleteCourse = ref(false)
    const courseKey = ref(null)

    const isLoading = computed(
      () => store.getters[COURSES_STORE_NAME + '/isLoading']
    )
    const isError = computed(
      () => store.getters[COURSES_STORE_NAME + '/isError']
    )
    const isReady = computed(
      () => store.getters[COURSES_STORE_NAME + '/isReady']
    )

    const confirmDeleteCourse = key => {
      courseKey.value = key
      isOpenModalDeleteCourse.value = true
    }
    const deleteCourse = async () => {
      await store.dispatch(
        COURSES_STORE_NAME + '/' + DELETE_COURSE,
        courseKey.value
      )

      if (isReady.value) {
        courseKey.value = null
        isOpenModalDeleteCourse.value = false
        pushNotification({
          title: 'Curso eliminado!',
          desciption: 'Tu curso se ha eliminado correctamente ',
          type: 'success'
        })
      }
      if (isError.value) {
        pushNotification({
          title: 'Algun error ha ocurrido!',
          desciption: 'Vuelve a intentarlo o contacta a soporte',
          type: 'error'
        })
      }
    }

    function pushNotification(data) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + PUSH_NOTIFICATION, data)
    }

    return {
      courses,
      confirmDeleteCourse,
      isOpenModalDeleteCourse,
      deleteCourse,
      isLoading
    }
  }
}
</script>
